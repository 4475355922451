.hot .container {
  width: 1366px;
  margin: 0 auto;
}
.hot .top-banner {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 999;
  background-color: #148eff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hot .top-banner .container {
  position: relative;
  margin: 0 auto;
  padding: 60px 90px;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hot .top-banner .container .title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}
.hot .top-banner .container .subtitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 45px;
}
.hot .top-banner .container .search {
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  padding: 0;
  border-radius: 10px;
  width: 600px;
  overflow: hidden;
}
.hot .top-banner .container .search .ant-input {
  border: none;
  outline: none !important;
  box-shadow: none;
  height: 50px;
}
.hot .top-banner .container .search .ant-btn {
  border-radius: 5px;
  margin-right: 15px;
  padding: 6px 15px;
  height: auto;
}
.hot .search-res {
  padding: 30px 0;
}
.hot .search-res > .container {
  display: flex;
  justify-content: space-between;
}
.hot .search-res > .container > .left {
  width: 480px;
  margin-right: 30px;
}
.hot .search-res > .container > .right {
  flex: 1 1;
  width: calc(100% - 480px - 30px);
}
.hot .search-res .tips {
  margin-bottom: 30px;
}
.hot .search-res .tips .title {
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  color: #3d3d3d;
}
.hot .search-res .tips .title .red {
  color: #fa0d2c;
}
.hot .search-res .search-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  gap: 30px;
}
.hot .search-res .search-list-item {
  background-color: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid transparent;
}
.hot .search-res .search-list-item:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}
.hot .search-res .search-list-item.cur {
  border-color: #148eff;
}
.hot .search-res .search-list-item.cur .head .name {
  color: #148eff;
}
.hot .search-res .search-list-item > div {
  width: 100%;
}
.hot .search-res .search-list-item .head {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100%;
}
.hot .search-res .search-list-item .head .name {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hot .search-res .search-list-item .head .name .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5px;
}
.hot .search-res .search-list-item .head .name .subtitle {
  font-weight: 100;
  font-size: 12px;
  color: #666;
}
.hot .search-res .search-list-item .head .price {
  font-size: 14px;
  font-weight: 700;
  color: #fa0d2c;
}
.hot .search-res .search-list-item .cont {
  display: flex;
  margin: 20px 0;
}
.hot .search-res .search-list-item .cont .tag {
  padding: 4px 8px;
  font-size: 12px;
  background-color: #eee;
  border-radius: 5px;
  margin-right: 10px;
}
.hot .search-res .search-list-item .foot {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.hot .info-cont {
  position: -webkit-sticky;
  position: sticky;
  top: 248px;
  background-color: #fff;
  border-radius: 5px;
  height: calc(100vh - 30px - 170px - 48px - 38px);
  overflow: hidden;
  max-width: 100%;
}
.hot .info-cont .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}
.hot .info-cont .head .info {
  overflow: hidden;
  max-width: 100%;
}
.hot .info-cont .head .info .title {
  display: flex;
  margin-bottom: 15px;
}
.hot .info-cont .head .info .title .name {
  font-size: 20px;
  font-weight: 600;
  color: #222;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hot .info-cont .head .info .title .price {
  font-size: 24px;
  font-family: kanzhun-mix, kanzhun-Regular;
  color: #fe574a;
  line-height: 29px;
  flex-shrink: 0;
  margin-left: 20px;
}
.hot .info-cont .head .info .tags {
  display: flex;
  align-items: center;
}
.hot .info-cont .head .info .tags-item {
  margin-right: 15px;
}
.hot .info-cont .head .info .tags-item > .anticon {
  margin-right: 5px;
  color: #aaa;
}
.hot .info-cont .head .action {
  margin-left: 15px;
}
.hot .info-cont .head .action .ant-btn {
  border-radius: 5px;
  height: auto;
  padding: 8px 30px;
}
.hot .info-cont .cont {
  height: calc(100vh - 30px - 170px - 48px - 38px - 96px);
  padding: 15px;
  overflow: hidden;
  overflow-y: auto;
}
.hot .info-cont .cont::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.hot .info-cont .cont::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}
.hot .info-cont .cont::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #ddd;
  border-radius: 10px;
}
.hot .info-cont .cont::-webkit-scrollbar-track-piece {
  background-color: #f1f1f1;
  border-radius: 0;
  -webkit-border-radius: 0;
}
.hot .info-cont .cont .page-header {
  margin-bottom: 15px;
}
.hot .info-cont .cont .page-header .title {
  font-size: 16px;
  font-weight: 600;
  color: #222;
  line-height: 22px;
}
.hot .info-cont .cont .tags {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.hot .info-cont .cont .tags-item {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 4px 12px;
  font-size: 13px;
  color: #666;
  line-height: 20px;
  float: left;
  margin: 4px;
}
.hot .info-cont .cont .intro {
  font-size: 14px;
  color: #222;
  line-height: 24px;
  text-align: justify;
  white-space: pre-wrap;
  word-break: break-all;
  margin-bottom: 15px;
  overflow: hidden;
}
.hot .info-cont .cont .intro pre,
.hot .info-cont .cont .intro textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  color: #222;
  line-height: 24px;
  text-align: justify;
  margin-bottom: 15px;
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  word-break: break-all;
  overflow: hidden;
  width: 100%;
  height: auto;
  border: none;
  resize: none;
  outline: none;
  box-shadow: none;
}
.hot .info-cont .cont .contact .anticon {
  margin-right: 5px;
  color: #aaa;
}
.hot .info-cont .cont .contact .name {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.hot .info-cont .cont .contact .time {
  font-size: 14px;
  margin-bottom: 15px;
}
/*
{
  "id": 3452,
  "user_enterprise_id": 2897,
  "occupation_id": 1,
  "title": "销售经理",
  "sex": 0,
  "min_age": 25,
  "max_age": 35,
  "number": 1,
  "number_now": 1,
  "work_provinces_code": "44",
  "work_cities_code": "4403",
  "work_areas_code": "440304",
  "work_address": "福田会展中心",
  "experience_id": 4,
  "education_id": 2,
  "min_salary_id": 13,
  "max_salary_id": 21,
  "intro": "医美行业新的械三产品，招有经验的销售主管，有大客户资源，负责组建团队，对业绩负责\n",
  "benefits_ids": "1,9,11",
  "interview_time": "周一上午10点",
  "interview_contact": "陈帮",
  "interview_address": "广州威尼国际2405",
  "state": 1,
  "is_top": 0,
  "issuer_id": null,
  "create_time": "2023-11-18 13:41:21",
  "update_time": "2023-11-18 13:41:21",
  "occupation_name": "销售/客服/技术支持",
  "work_provinces_name": "广东省",
  "work_cities_name": "深圳市",
  "work_areas_name": "福田区",
  "experience_name": "3~5年",
  "education_name": "本科",
  "min_salary_name": "12K",
  "max_salary_name": "20K",
  "benefits": "高额提成/交通补助/五险",
  "resume_count": 0
}
*/
