.d-inline-block {
  display: inline-block !important;
}
.service .ant-layout {
  background-color: #fff !important;
}
.service .container {
  width: 1366px;
  margin: 0 auto;
}
.service .container img {
  max-width: 100%;
}
.service .top-banner {
  background-color: #148eff;
  margin: 0 auto 120px;
  min-width: 1280px;
  display: flex;
  align-items: center;
}
.service .top-banner .container {
  position: relative;
  margin: 0 auto;
  padding: 150px 270px;
  width: 100%;
  color: #fff;
}
.service .top-banner .container .title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}
.service .top-banner .container .subtitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 45px;
}
.service .top-banner .page-header {
  display: none;
}
.service .intro-info {
  margin: 95px auto 67px;
}
.service .intro-info .title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: rgba(0, 132, 255, 0.9216);
  margin-bottom: 15px;
}
.service .intro-info .subtitle {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: #3d3d3d;
  margin-bottom: 60px;
}
.service .intro-info .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service .intro-info .list .item {
  width: 128px;
  height: 49px;
  border-radius: 5px;
  background: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d3d3d;
  font-size: 14px;
  font-weight: 600;
}
.service .block .container > .title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: rgba(0, 132, 255, 0.9216);
}
.service .fast {
  margin-bottom: 120px;
}
.service .fast .title {
  margin-bottom: 30px;
}
.service .fast .fast-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 120px 90px;
  border-radius: 5px;
  opacity: 1;
  background: rgba(0, 132, 255, 0.9216);
}
.service .fast .fast-list-item {
  position: relative;
  padding-right: 100px;
  color: #fff;
  text-align: center;
}
.service .fast .fast-list-item::after {
  content: "";
  width: 13px;
  height: 14px;
  background: url(../../atess/image//indexnew/service/arrows.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 30px;
  right: 0;
}
.service .fast .fast-list-item:last-child {
  padding-right: 0;
}
.service .fast .fast-list-item:last-child::after {
  display: none;
}
.service .fast .fast-list-item .cover {
  margin-bottom: 60px;
  background-color: #fff;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
