.new_resume .page-header .title {
  padding-left: 15px;
  border-left: 3px solid #4193f6;
  margin-bottom: 15px !important;
}
.new_resume .ant-form-item-label > label {
  color: #999;
  font-size: 12px;
}
.new_resume .empty {
  padding: 15px;
  margin-bottom: 15px;
}
