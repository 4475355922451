.selected .container {
  width: 1366px;
  margin: 0 auto;
}
.selected .top-banner {
  background-color: #148eff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected .top-banner .container {
  position: relative;
  margin: 0 auto;
  padding: 60px 90px;
  width: 100%;
  color: #fff;
}
.selected .top-banner .container .search {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0;
  border-radius: 10px;
  width: 600px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 30px;
}
.selected .top-banner .container .search .ant-input {
  border: none;
  outline: none !important;
  box-shadow: none;
  height: 50px;
}
.selected .top-banner .container .search .ant-btn {
  border-radius: 5px;
  margin-right: 15px;
  padding: 6px 15px;
  height: auto;
}
.selected .top-banner .container .links {
  width: 100%;
  text-align: center;
}
.selected .top-banner .container .links a {
  color: #fff;
}
.selected .search-res {
  background-color: #fff;
  padding: 30px 0;
}
.selected .search-res .tips {
  margin-bottom: 30px;
}
.selected .search-res .tips .title {
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  color: #3d3d3d;
}
.selected .search-res .tips .title .red {
  color: #fa0d2c;
}
.selected .search-res .search-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  gap: 30px;
}
.selected .search-res .search-list-item {
  position: relative;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  cursor: pointer;
  transition: all 0.3s;
}
.selected .search-res .search-list-item:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}
.selected .search-res .search-list-item > div {
  width: 100%;
}
.selected .search-res .search-list-item .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected .search-res .search-list-item .head .cover {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.selected .search-res .search-list-item .head .name {
  font-weight: 700;
}
.selected .search-res .search-list-item .head .price {
  font-weight: 700;
  color: #fa0d2c;
}
.selected .search-res .search-list-item .cont {
  display: flex;
  margin: 20px 0;
}
.selected .search-res .search-list-item .cont .tag {
  padding: 4px 8px;
  font-size: 12px;
  background-color: #eee;
  border-radius: 5px;
  margin-right: 10px;
}
.selected .search-res .search-list-item .foot {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
