.feedback-btn {
  position: fixed;
  right: 10px;
  bottom: 50px;
  z-index: 797979;
  width: 60px;
  height: 60px;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #eee;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}
.feedback-btn .text {
  display: inline-block;
}
.ant-modal-mask {
  z-index: 1001 !important;
}
.ant-modal-wrap {
  z-index: 100002 !important;
}
.wait.ant-modal-confirm {
  border-radius: 10px;
  overflow: hidden;
  background-image: url(../../atess/image/feedback.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  background-color: #fff;
}
.wait.ant-modal-confirm .ant-modal-content {
  background-color: transparent;
}
.wait.ant-modal-confirm .header {
  margin-bottom: 15px;
}
.wait.ant-modal-confirm .title {
  font-size: 24px;
  margin-bottom: 15px;
}
.wait.ant-modal-confirm .subtitle {
  color: #999;
}
