.d-inline-block {
  display: inline-block !important;
}
.indexnew .ant-layout {
  background-color: #fff !important;
}
.indexnew .container {
  width: 1366px;
  margin: 0 auto;
}
.indexnew .container img {
  max-width: 100%;
}
.indexnew .top-banner {
  background-color: #148eff;
  margin: 0 auto 120px;
  min-width: 1280px;
  display: flex;
  align-items: center;
}
.indexnew .top-banner .container {
  position: relative;
  margin: 0 auto;
  padding: 150px 90px;
  width: 100%;
  color: #fff;
}
.indexnew .top-banner .container .title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}
.indexnew .top-banner .container .subtitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 45px;
}
.indexnew .top-banner .container .search {
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  padding: 0;
  border-radius: 10px;
  width: 400px;
  overflow: hidden;
}
.indexnew .top-banner .container .search .ant-input {
  border: none;
  outline: none !important;
  box-shadow: none;
  height: 50px;
}
.indexnew .top-banner .container .search .ant-btn {
  border-radius: 5px;
  margin-right: 15px;
  padding: 6px 15px;
  height: auto;
}
.indexnew .top-banner .login-wrap {
  position: absolute;
  top: 75px;
  right: 0px;
  width: 320px;
  height: 310px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 69px 0 rgb(0, 17, 86 0.17);
  z-index: 11;
  padding: 30px 30px;
  color: #fff;
}
.indexnew .top-banner .login-wrap .page-header {
  margin-bottom: 30px;
}
.indexnew .top-banner .login-wrap .page-header > * {
  border-left: 3px solid #2384c7;
  padding-left: 15px;
}
.indexnew .top-banner .login-wrap .ant-checkbox-wrapper,
.indexnew .top-banner .login-wrap .pg-entrance-form_item-tips {
  color: #fff;
}
.indexnew .top-banner .login-wrap .ant-btn.ant-btn-default {
  display: none;
}
.indexnew .top-banner .page-header {
  display: none;
}
.indexnew .intro-info {
  margin: 95px auto 67px;
}
.indexnew .intro-info dl {
  float: left;
  width: calc(25% - 2px);
  text-align: center;
  border-right: 1px dotted #d2d2d2;
}
.indexnew .intro-info dl:last-child {
  border-right: none;
}
.indexnew .intro-info dl dt {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  height: 36px;
}
.indexnew .intro-info dl dt span {
  position: relative;
  padding-left: 35px;
}
.indexnew .intro-info dl dt span::before {
  content: "";
  display: block;
  width: 21px;
  height: 21px;
  background: url(../../atess/image/introicon.6f517262.png) no-repeat;
  position: absolute;
  top: 0px;
  left: 0;
}
.indexnew .intro-info dl dt::after {
  content: "";
  display: block;
  width: 55px;
  height: 2px;
  background: rgba(0, 132, 255, 0.9216);
  font-size: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -9px;
}
.indexnew .intro-info dl dd {
  color: #666;
  font-size: 14px;
  margin-top: 15px;
}
.indexnew .intro-info dl:nth-of-type(2) dt span:before {
  background-size: 21px auto;
  background-position: 0 -21px;
}
.indexnew .intro-info dl:nth-of-type(3) dt span:before {
  background-size: 21px auto;
  background-position: 0 -42px;
}
.indexnew .intro-info dl:nth-of-type(4) dt span:before {
  background-size: 21px auto;
  background-position: 0 -63px;
}
.indexnew .block .container > .title {
  text-align: center;
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: #3d3d3d;
  margin-bottom: 30px;
}
.indexnew .used {
  margin-bottom: 60px;
}
.indexnew .used .logo-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}
.indexnew .used .logo-list .logo {
  width: 100%;
  height: 65px;
  border-radius: 5px;
  background-color: #d8d8d8;
}
.indexnew .whychoose {
  margin-bottom: 60px;
}
.indexnew .whychoose .intro-cont {
  border: 1px solid rgba(216, 216, 216, 0.4);
  border-radius: 10px;
}
.indexnew .whychoose .intro-cont .navs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(216, 216, 216, 0.4);
  padding: 0 30px;
}
.indexnew .whychoose .intro-cont .navs-item {
  padding: 20px 15px;
  text-align: center;
  font-family: Source Han Sans;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: rgba(61, 61, 61, 0.87);
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s;
}
.indexnew .whychoose .intro-cont .navs-item.cur {
  color: rgba(0, 132, 255, 0.9216);
  border-bottom: 2px solid rgba(0, 132, 255, 0.9216);
}
.indexnew .whychoose .intro-cont .carousel-item {
  display: inline-flex !important;
  justify-content: space-between;
  padding: 90px 30px;
}
.indexnew .whychoose .intro-cont .carousel-item .title {
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: rgba(0, 132, 255, 0.9216);
  margin-bottom: 60px;
}
.indexnew .whychoose .intro-cont .carousel-item .subtitle {
  font-family: Source Han Sans;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: rgba(61, 61, 61, 0.52);
}
.indexnew .start {
  padding: 60px 15px;
  background-color: #0084ff;
  text-align: center;
}
.indexnew .start .title {
  color: #fff;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;
}
.indexnew .start .btn-cont .ant-btn {
  border: none;
  font-family: Source Han Sans;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  -webkit-font-feature-settings: "kern" on;
  font-feature-settings: "kern" on;
  color: #3d3d3d;
  border-radius: 5px;
  padding: 4px 15px !important;
}
.indexnew .footer {
  background: #000;
  padding: 50px 0 0;
  background: url(../../atess/image/indexnew/Bottom-bg.jpg) no-repeat center top;
  background-size: 100% 100%;
}
.indexnew .footer .nav {
  border-right: 1px solid #666;
}
.indexnew .footer .nav .title,
.indexnew .footer .nav a {
  min-width: 180px;
}
.indexnew .footer .nav .title {
  background: url(../../atess/image/indexnew/bottom-icon01.png) no-repeat left center;
  padding-left: 30px;
  margin-bottom: 15px;
}
.indexnew .footer .nav .title a {
  color: rgba(255, 255, 255, 0.5);
  display: block;
  font-size: 16px;
}
.indexnew .footer .nav .ant-col > a {
  color: #fff;
  padding: 3px 0;
  padding-left: 30px;
  display: block;
  font-size: 14px;
}
.indexnew .footer .copyright {
  text-align: center;
  border-top: #494949 1px solid;
  line-height: 60px;
  color: #494949;
  margin-top: 50px;
  padding: 30px 0;
}
.indexnew .footer .copyright a {
  color: #494949;
}
@keyframes ani-1 {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}
@-webkit-keyframes ani-1 {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}
@keyframes ani-2 {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@-webkit-keyframes ani-2 {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
footer {
  background-color: #fff;
  padding: 0;
  color: rgba(255, 255, 255, 0.3);
  text-align: left;
}
footer p {
  font-size: 14px;
  margin: 0;
}
footer .container {
  width: 100%;
  padding: 0 80px;
}
footer .footer-links {
  padding: 80px 0 72px;
  border-bottom: 1px solid #ededed;
}
footer .footer-links .footer-links-title {
  margin-bottom: 46px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 700;
  color: #000;
}
footer .footer-links .footer-links-list {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
footer .footer-links .footer-links-item {
  margin-bottom: 7px;
}
footer .footer-links .footer-links-item .footer-links-link {
  transition: 0.3s ease-out;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  color: #767676;
}
footer .footer-links .footer-links-item .footer-links-link:hover {
  color: #333;
}
footer .copyright {
  color: #767676;
  padding: 15px 0;
}
